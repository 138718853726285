import { LitElement, html, css } from 'lit';
import { krumStyles } from '../styles/krum-styles.js';
import { flexStyles } from '../styles/flex-styles.js';
import '../components/krum-card-image.js';

export class KrumSectionSolutions extends LitElement {
    static get properties() {
        return {
            type: {
                type: String,
                attribute: true,
                reflect: true,
            },
        };
    }
    constructor() {
        super();
    }
    static get styles() {
        return [
            flexStyles,
            krumStyles,
            css`
              :host {
                display: block;
                overflow-x: hidden;
                background-color: var(--krum-background-color);
                font-family: var(--krum-font-family);
                max-width: var(--krum-content-max-width);
                margin-left: auto;
                margin-right: auto;
              }

              :host([type="secondary"]) {
                background-color: var(--krum-secondary-background-color);
              }

              :host([type="action"]) {
                background-color: var(--krum-secondary-text-color);
              }

              :host([type="action"]) .section-title {
                color: var(--krum-white-text-color);
              }

              :host([type="action"]) .section-text {
                  color: var(--krum-muted-white-text-color);
              }

              h1 {
                /* 42px Medium Display */

                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 42px;
                line-height: 56px;
                /* identical to box height, or 133% */
                letter-spacing: 0.02em;
                margin-bottom: 4px;
              }

              span {

                /* 18px Regular Text */

                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 28px;
                /* or 156% */
                letter-spacing: 0.02em;
              }
              h3 {
                margin: 0px;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 34px;
                /* identical to box height, or 142% */
                letter-spacing: 0.02em;

                margin-top: 16px;
              }

              hr {
                border: 0px;
                border-top: 1px solid var(--krum-color-medium-gray);
              }



              .section {
                padding: 32px;
                padding-bottom: 100px;
                padding-top: 40px;
              }

              .section > * {
                max-width: var(--krum-content-max-width);
              }

              .section-title {
                font-size: 36px;
                font-weight: bold;
                margin-bottom: 16px;
                color: var(--krum-secondary-text-color);
              }

              .section-text {
                font-size: 24px;
                font-weight: 300;
                line-height: 1.5;
                color: var(--krum-focused-action-color);
                text-align: justify;
              }

              .card-section {
                margin-top: 80px;
                margin-right: 120px;
                width: 440px;
              }

              .explore {

                margin-top: 8px;
                /* 16px Bold Display */

                font-family: 'Poppins';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 26px;
                /* identical to box height, or 162% */
                display: flex;
                align-items: center;
                letter-spacing: 0.02em;
                color: var(--krum-action-color);

                /*  remove when pages are ready  */
                display: none;
              }

              .explore > a {
                color: var(--krum-action-color);
                text-decoration: none;
              }
              .explore-text {

                /* 16px Bold Display */

                font-family: 'Poppins';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 26px;
                /* identical to box height, or 162% */
                display: flex;
                align-items: center;
                letter-spacing: 0.02em;

              }

              ul.no-bullets {
                list-style-type: none; /* Remove bullets */
                padding: 0; /* Remove padding */
                margin: 0; /* Remove margins */

                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 30px;
                /* or 150% */
                letter-spacing: 0.02em;

                margin-top: 8px;
              }

              .icon {
                height: 56px;
                width: 56px;
              }

              #header {
                /* max-width: 496px; */
              }
              @media all and (max-width: 1280px) {

                .card-section {
                  margin-right: 36px;
                }
              }
              @media all and (max-width: 1000px) {

                .card-section {
                  margin-right: 0px;
                }
              }
              /**
              * Mobile considerations for font sizes and section paddings at the
              * 540px breakpoint.  This will also adjust padding for the tile image
              * and the size of the overlay title.
              */
              @media all and (max-width: 540px) {
                h1 {
                  font-size: 28px;
                  line-height: 38px;
                }

                span {
                  font-size: 16px;
                  line-height: 22px;
                }

                .section {
                  padding: 64px 32px;
                }

                .card-section > h3 {
                  font-size: 18px;
                }

                .card-section {
                  margin-right: 0px;
                }

                ul.no-bullets {
                  font-size: 16px;
                }

                .section-title {
                  font-size: 24px;
                }

                .section-text {
                  font-size: 16px;
                }

              }
            `,
        ];
    }
    render() {
        return html`
        <section class="section vertical layout">
            <div id="header">
              <h1>Engineering Solutions and Services</h1>
              <span>We get in the trenches with you to provide hands-on implementation, skills enablement, and strategy. Krum implements standardized solution stacks or develops new technologies and capabilities in place to give your teams the tools they need to sustain innovation and efficiency.</span>
            </div>
            <div class="layout horizontal wrap">
              

            <div class="card-section">
                <div class="icon" style="background-image: url('/images/icon-architect.png')"></div>
                <h3>
                  1. Implement
                </h3>
                <ul class="no-bullets">
                  <li>Cloud-Native Application Development</li>
                  <li>Application Re-Development</li>
                  <li>Cloud Architecture and Migration</li>
                  <li>Platform and Stack Quickstarts</li>
                </ul>
                <div class="explore">
                  <a href="#">
                    <div class="explore-text">EXPLORE&nbsp;<img src="/images/icon-chevron-right.png"></div>

                  </a>
                </div>
              </div>


              <div class="card-section">
                <div class="icon" style="background-image: url('/images/icon-dashboard.png')"></div>
                <h3>
                  2. Enable
                </h3>
                <ul class="no-bullets">
                  <li>Cloud-Native Best Practices</li>
                  <li>Platform Engineering and Strategy</li>
                  <li>Kubernetes / Cloud Native</li>
                  <li>Design and Component Systems</li>
                </ul>
                <div class="explore">
                  <a href="#">
                    <div class="explore-text">EXPLORE&nbsp;<img src="/images/icon-chevron-right.png"></div>

                  </a>
                </div>
              </div>

              <div class="card-section">
                <div class="icon" style="background-image: url('/images/icon-developercloud.png')"></div>
                <h3>
                  3. Support
                </h3>
                <ul class="no-bullets">
                  <li>Developer Experience</li>
                  <li>Delivery Workflows & SRE</li>
                  <li>Data & Application Intelligence</li>
                  <li>Center of Excellence & Dojo</li>
                </ul>
                <div class="explore">
                  <a href="#">
                    <div class="explore-text">EXPLORE&nbsp;<img src="/images/icon-chevron-right.png"></div>

                  </a>
                </div>
              </div>
              

              <div class="card-section">
                <div class="icon" style="background-image: url('/images/icon-handshake.png')"></div>
                <h3>
                  4. Partner
                </h3>
                <ul class="no-bullets">
                  <li>End-user Implementation Support</li>
                  <li>Upstream Project Contribution</li>
                  <li>Solution Stacks</li>
                  <li>Workshop and Courseware Development</li>
                </ul>
                <div class="explore">
                  <a href="#">
                    <div class="explore-text">EXPLORE&nbsp;<img src="/images/icon-chevron-right.png"></div>
                  </a>
                </div>
              </div>
            </div>
        </section>
    `;
    }
}

customElements.define('krum-section-solutions', KrumSectionSolutions);
